import { attr, belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import BasePackage from 'crakn/models/base-package';

const Validations = buildValidations({
  name: validator('presence', true),
  pricingAmount: validator('number', {
    allowBlank: false,
    allowNone: false,
  }),
  total: validator('number', {
    allowString: true,
    allowBlank: false,
    allowNone: false,
  }),
  'invoiceCategory.id': validator('presence', true),
});

export default class V2PackageModel extends BasePackage.extend(Validations) {
  @attr('string') name;
  @attr('string') description;
  @attr('number') customOrder;
  @attr('number') invoiceCategoryId;
  @attr('price') pricingAmount;
  @attr('string', { defaultValue: 'normal' }) pricingMethod;

  @belongsTo('v2/generalPriceList', { async: true }) generalPriceList;
  @belongsTo('v2/invoiceCategory', { async: true }) invoiceCategory;

  @hasMany('v2/package-slot', { async: true }) packageSlots;
  @hasMany('v2/package-slot-product', { async: true }) packageSlotProducts;
  @hasMany('v2/product') products;
}
