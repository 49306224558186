import Model, { hasMany, attr } from '@ember-data/model';
import { cleanPrice } from 'crakn/utils/data-helpers';

export default class BasePackageModel extends Model {
  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') updatedAt;
  @attr('price') pricingAmount;
  @attr('string', { defaultValue: 'normal' }) pricingMethod;

  @hasMany('product') products;

  get allowanceTotal() {
    let total = 0;

    if (this.packageSlots) {
      this.packageSlots.forEach((ps) => {
        total += ps.allowanceAmount;
      });
    }

    return total;
  }

  get discountedTotal() {
    return this.total - this.calculatedDiscount;
  }

  /** Returns the sum of all product prices */
  get productsTotal() {
    let total = 0;

    if (this.products) {
      this.products.forEach((item) => {
        if (item) {
          const price = cleanPrice(item.price);
          const allowanceTotal = cleanPrice(this.allowanceTotal);

          if (price) {
            total += price;
          } else if (allowanceTotal) {
            total += allowanceTotal;
          }
        }
      });
    }

    return total;
  }

  get total() {
    return parseFloat(cleanPrice(this.productsTotal)).toFixed(2);
  }

  get calculatedDiscount() {
    if (this.isDeleted) {
      return false;
    }

    const pricingMethod = this.pricingMethod;
    const pricingAmount = cleanPrice(this.pricingAmount);

    let calculatedDiscount = 0.0;

    if (pricingMethod === 'fixed') {
      calculatedDiscount = this.total - pricingAmount;
    } else if (pricingMethod === 'amount') {
      calculatedDiscount = pricingAmount;
    }

    return calculatedDiscount;
  }
}
